<template>
    <div class="xw_page_container">
        <div class="member_header">
            <div class="member_title_name " v-if="$store.state.windowWidth > 768">
                {{ $t('幫助中心') }}
            </div>
            <div class="search_item" v-if="$store.state.windowWidth > 768">
                <ul class="member_tab_list">
                    <li @click="tabActive = index" v-for="(item, index) in tabList" :key="index" class="member_tab_item"
                        :class="{ active: tabActive === index }">
                        {{ $t(item) }}
                    </li>
                </ul>
            </div>

            <div class="bg_header" v-else>
                <div class="header_content">
                    <button @click="goBack" type="button" class="btn_back"></button>
                    <span>{{ $t(tabList[tabActive]) }}</span>
                </div>
            </div>
        </div>

        <div class="member_content">
            <iframe v-if="tabActive == tabEnum.TECHNICALSUPPORT" src="https://777.wb222.net"
                style="overflow-x: hidden; border: none; width: 100%; height: 647px;" frameborder="0"></iframe>
            <div class="HelpQcontant" v-if="tabActive == tabEnum.REMOTEASSIS">
                <div class="HelpQADeposit_dl">
                    <dt>
                        {{ $t('遠端協助使用指引') }}
                    </dt>
                    <br />
                    <dd>
                        <p>1.<span style="display: inline-block; width: 7px;"></span>前往AnyDesk官網（
                            <a @click="pageJump('https://anydesk.com/zht')"
                                style="color:blue; text-decoration: underline;" target="Viewer">https://anydesk.com/zht
                            </a>），下載遠端協助網址。
                        </p>
                        <p>
                            2.<span style="display: inline-block; width: 7px;"></span>下載後並執行
                            <img src="/images/icon_anydesk.jpg" />
                            進行安裝。※安裝教學流程可參照（
                            <a onclick="javascript"
                                style="color:blue; text-decoration: underline;" target="download"><span
                                    style="font-weight:900;">遠端協助程式安裝教學</span></a>）
                        </p>
                        <p>3.<span
                                style="display: inline-block; width: 7px;"></span>將您此工作台的【遠端地址】提交給客服人員並選擇【接受】，即可為您進行遠端協助服務。
                        </p>
                        <br />
                        <p>
                            <img style="border:1px solid #cfcfcf;width: 600px;"
                                src="../../assets/images/xw/anydesk_pic.jpg" />
                        </p>
                    </dd>
                    <dt>遠端協助的優點</dt>
                    <br />
                    <dd>
                        <p>1.<span style="display: inline-block; width: 7px;"></span>下載無需任何費用。</p>
                        <p>2.<span style="display: inline-block; width: 7px;"></span>遠端協助程式使用完畢後即可關閉與卸載，無須擔心安全性問題。</p>
                        <p>3.<span
                                style="display: inline-block; width: 7px;"></span>可由客服或電腦工程人員為您遠端操作電腦，妥善協助您查看並解決當前電腦或軟體問題。
                        </p>
                    </dd>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import deposit from "./xwComponents/deposit.vue";
import xwWithdrawal from "./xwComponents/xwWithdrawal.vue";
import transactionHistory from "./xwComponents/transactionHistory.vue";
import xwInformation from "./xwComponents/xwInformation.vue";

import xwTabbar from "@/components/xwTabbar.vue";

export default {
    components: {
        deposit,
        xwWithdrawal,
        xwTabbar,
        transactionHistory,
        xwInformation
    },
    data() {
        return {
            tabEnum: {
                COMMONPROBLEM: 0,
                TECHNICALSUPPORT: 1,
                REMOTEASSIS: 2,
            },
            tabActive: 0,
            tabList: ["常見問題", "技術支援", '遠端協助']
        }
    },
    mounted() {
        this.tabActive = this.tabEnum[this.$route.params.pageName] || 0
    },
    watch: {
        "$route.params": function (v) {
            this.tabActive = this.tabEnum[this.$route.params.pageName] || 0
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        pageJump(path) {
            if (this.$store.state.windowWidth > 1000) {
                this.openNewWindowAutoHeightName(path, 'ExampleWindow', 1002, 674)
            } else {
                window.open(path)
            }
        },
    }
}
</script>

<style lang="less" scoped>
.xw_page_container {
    width: 100vw;
    min-height: var(--vh);
    background-color: #ebebeb;
}

.member_header {
    position: relative;
    width: 100%;
    height: 60px;
    background-color: #3a9b7c;

    @media (max-width:768px) {
        position: fixed;
        top: 0;
        left: 0;
        height: 40px;
        background-color: #3f9eff;
        z-index: 10;
    }
}

.member_title_name {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    margin-left: 34px;
}

.search_item {
    margin-left: 0;
    height: 0;
    position: absolute;
    top: 24px;
    left: 179px;
    width: max-content;
}

.member_tab_list {
    list-style: none;
    display: flex;
    align-items: center;
    user-select: none;
}

.member_tab_item {
    min-width: auto;
    padding: 0 17px;
    text-align: center;
    height: 36px;
    line-height: 36px;
    width: max-content;
    font-size: 13px;
    font-weight: normal;
    color: #fff;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
}

.member_tab_item.active {
    background-color: #ebebeb !important;
    color: #3a9b7c;
}

.bg_header {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 99;
}

.header_content {
    position: relative;
    color: #fff;
    width: 100%;
    height: 100%;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    font-weight: bold;
}

.btn_back {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
    width: 40px;
    display: block;
    background: url('../../assets/images/xw/btn_back.svg') no-repeat center;
    background-size: auto 40%;
}

.member_content {
    width: 100%;
    margin: 0 auto;
    padding: 15px;
    height: calc(var(--vh) - 80px);
    overflow: auto;
    
    @media (max-width:768px) {
        padding: 0;
    }
}

.icon_inforMoney {
    position: absolute;
    top: 0;
    right: 16px;
    height: 100%;
    color: #feea11;
}

.HelpQADeposit_dl {
    margin-bottom: 10px;
    padding: 0 20px;

    dt {
        color: #1c8f91;
        margin-top: 28px;
    }
}
.HelpQcontant{
    background-color: #fff;
    overflow: hidden;
    zoom: 1;
    line-height: 20px;
    color: #4d4d4d;
    border: #d4d4d4 solid 1px;
}
</style>